/* ==========================================================================
   LETS BUILD TODOIST
   ========================================================================== */
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900');

// General Settings
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: 'Roboto', sans-serif;
  color: #202020;
  background-color: #fafafa;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  margin: 0;
  padding: 0;
}

// Variables
$generic-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 11px;
$generic-border: 1px solid #f1f1f1;
$generic-bg: #db4c3f;

/* Mixins
  ============================================ */
@mixin box-overlay() {
  -webkit-box-shadow: $generic-box-shadow;
          box-shadow: $generic-box-shadow;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 85px;
  border-radius: 3px;
}

@mixin va($va-align: center) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  @if $va-align != center {
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
  } @else {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin btn($width) {
  width: $width;
  background-color: $generic-bg;
  color: #fff !important;
  border: 1px solid transparent;
  margin-right: 5px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 13px !important;
  line-height: 17px;
  padding: 6px 12px 7px 12px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  border-radius: 3px !important;
  text-decoration: none !important;
  text-align: center;
  cursor: pointer;
}

@mixin cancel() {
  color: #555;
  cursor: pointer;
  font-size: 14px;
  margin: 2px 5px;
}

@mixin transparent-button() {
  background-color: transparent;
  border: 0;
}

@mixin task-item() {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 18px;
  color: #333;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  list-style-type: none;
  border-bottom: 1px solid #f0f0f0;
  padding-left: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }
}

@mixin mq($from) {
  @media (max-width: $from) {
    @content;
  }
}

/* Darkmode
  ============================================ */
.darkmode {
  header {
    -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15);
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15);
    background-color: #282828;
    border-color: transparent;
  }
}

/* Header
  ============================================ */
header {
  border-bottom: solid 1px #ca2100;
  background-color: $generic-bg;
  -webkit-transition: height 200ms ease-in;
  transition: height 200ms ease-in;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  height: 44px;
  z-index: 400;
  position: fixed;
  top: 0;
  width: 100%;

  @include mq('900px') {
    padding: 0 10px;
  }

  nav {
    display: grid;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    max-width: 922px;
    margin: auto;
    height: 44px;

    p, li {
      color: white;
    }

    div {
      &.logo {
        padding-left: 8px;
        grid-area: 1 / 1;

        img {
          width: 24px;
        }

      }

      &.settings {
        grid-area: 1 / 2;
        text-align: right;

        ul {
          float: right;
        }

        ul li {
          @include va;

          list-style-type: none;
          cursor: pointer;
          width: 30px;
          height: 30px;
          text-align: center;
          vertical-align: middle;
          float: left;

		  &:first-of-type {
			margin-right: 0.75rem;
		  }

          &:hover {
            border-radius: 3px;
            background-color: rgba(255,255,255,0.2);
          }

          &.settings__add {
            margin-right: 15px;
            font-size: 30px;

            button {
              @include transparent-button();
              color: white;
              cursor: pointer;
            }
          }

          &.settings__darkmode {
            button {
              @include transparent-button();
              color: white;
              cursor: pointer;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;

              svg {
                width: 20px;
                height: 20px;
              }
            }

          }
        }
      }
    }
  }
}

/* Sidebar
  ============================================ */
.sidebar {
  @include no-select();
  width: 266px;
  height: calc(100vh);
  padding-top: 74px;
  position: fixed;
  overflow-x: hidden;
  overflow-y: hidden;
  border-right: $generic-border;
  background-color: #fafafa;

  @include mq('900px') {
    display: none;
  }

  li {
    &:hover .sidebar__project-delete {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }

  &__project {
    cursor: pointer;

    div:nth-child(1) {
      padding: 5px 0;
      padding-left: 10px;
      padding-right: 15px;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }

    &-delete {
      margin-left: auto;
      display: none;

      svg {
        color: #cacaca;
      }
    }

    &.active, &:hover {
      font-weight: bold;
      background-color: white;
    }

    &:nth-child(1) {
      .sidebar__dot {
        color: #6accbc;
      }
    }
    &:nth-child(2) {
      .sidebar__dot {
        color: #fad003;
      }
    }
    &:nth-child(3) {
      .sidebar__dot {
        color: #ff8d85;
      }
    }
    &:nth-child(4) {
      .sidebar__dot {
        color: #ff9932;
      }
    }
    &:nth-child(5) {
      .sidebar__dot {
        color: #af38eb;
      }
    }
  }

  &__projects {
    padding-left: 3px;
  }

  &__project {
    @include va('left');
  }

  &__dot {
    margin-right: 10px;
    font-size: 30px;
  }

  &__middle {
    margin-top: 20px;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: $generic-border;
    padding-left: 10px;
    cursor: pointer;

    span {
      color: #0000008a;
      margin-right: 10px;

      svg.hidden-projects {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
      }
    }

    h2 {
      margin: 0;
      color: #333;
      font-size: 15px;
      font-weight: bold;
      padding-bottom: 20px;
    }
  }

  &__generic {
    li {
      list-style-type: none;
      color: #333;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      cursor: pointer;
      line-height: 1.50;
      font-size: 15px;
      padding-left: 0;
      padding-right: 0;

      div:nth-child(1) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 10px 0 10px 10px;
        width: 100%;

        span:first-of-type {
          margin-right: 10px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;

          svg {
            width: 18px;
            height: 18px;
            color: #555;
          }
        }
      }

      &.active, &:hover {
        font-weight: bold;
        background-color: white;
      }
    }
  }
}

/* Show Confirm Delete
  ============================================ */
.project-delete-modal {
  position: relative;

  &__inner {
    @include box-overlay();
    z-index: 99;
    width: 200px;
    padding: 10px;
    top: 10px;
    right: 0;
    background-color: white;

    p {
      margin-top: 5px;
      margin-bottom: 5px;
      line-height: normal;
      font-weight: normal;
      font-size: 15px;
    }

    button {
      @include btn('50px');
    }
    span {
      @include cancel();
      font-weight: normal;
    }
  }
}

/* Main Content
  ============================================ */
.content {
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-template-columns: 1fr auto;
  max-width: 922px;
  margin: auto;
}

/* Tasks
  ============================================ */
.tasks {
  width: 656px;
  background-color: white;
  margin-left: 266px;
  border-right: $generic-border;
  min-height: calc(100vh);
  vertical-align: top;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 80px;
  padding-bottom: 84px;

  @include mq('900px') {
    margin-left: 0;
    width: auto;
  }

  h2 {
    font-size: 20px;
    font-weight: normal;
    margin: 0 30px 20px 0;
  }

  .add-task {
    margin-top: 20px;
  }

  &__list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    line-height: 18px;
    color: #333;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    list-style-type: none;
    border-bottom: 1px solid #f0f0f0;

    .checkbox-holder {
      margin-right: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      line-height: 16px;

      .checkbox {
        cursor: pointer;
        border-color: gray;
        color: #343434;
        height: 16px;
        width: 16px;
        display: block;
        border: 1px solid gray;
        border-radius: 16px;
      }
    }
  }
}

/* Add Task
  ============================================ */
.add-task {
  &__overlay {
    background: rgba(102,102,102,0.5);
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
    height: calc(100vh);

    .add-task__main {
      @include mq('900px') {
        width: 90%;
      }

      overflow: visible;
      margin-bottom: 20px;
      margin-top: 55px;
      border-radius: 5px;
      background-color: white;
      position: absolute;
      left: 50%;
      top: 50%;
      padding: 20px 30px;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);

      .header {
        font-size: 13px;
        margin-bottom: 20px;
        float: left;
      }
    }

    .add-task__cancel-x {
      cursor: pointer;
      color: gray;
      float: right;
      font-weight: bold;
      font-size: 12px;
      margin-top: 10px;
    }
  }

  &__main {
    position: relative;
  }

  &__project, &__date {
    cursor: pointer;
    float: right;
    color: gray;
    margin: 20px 10px;
  }

  &__project {
    margin-right: 0;
  }

  &__content {
    width: 100%;
    border: 1px solid #ddd;
    background-color: white !important;
    border-radius: 3px;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__cancel, &__submit {
    @include no-select;
  }

  &__cancel {
    @include cancel();
  }

  &__submit {
    @include btn('90px');
  }
}

.add-task__shallow, .add-project {
  @include no-select;
  cursor: pointer;
  margin-bottom: 20px;
}

.add-task__plus, .add-project__plus {
  color: #dd4b39;
  font-size: 18px;
}

.add-task__text, .add-project__text {
  display: inline-block;
  color: #545454;
  font-size: 14px;
  margin-left: 15px;
}

/* Task Date
  ============================================ */
.task-date {
  @include box-overlay();
  background-color: #fff;
  width: 200px;
  right: 0;

  &__list li {
    @include task-item();
    padding: 0;

    div:nth-child(1) {
      padding: 10px;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;

      span:first-of-type {
        margin-right: 10px;

        svg {
          fill: grey;
        }
      }
    }
  }
}

/* Project Overlay
  ============================================ */
.project-overlay {
  @include box-overlay();
  background-color: white;

  @include mq('900px') {
    width: 200px;
    right: 0;
  }

  &__list li {
    @include task-item();
    padding: 0;

    div:nth-child(1) {
      width: 100%;
      padding: 10px;
    }
  }
}

/* Add Project
  ============================================ */
.add-project {
  padding-left: 13px;
  padding-right: 20px;
  margin-top: 20px;

  &__input {
    margin-bottom: 20px;
  }

  &__name {
    width: 100%;
    border: 1px solid #ddd;
    background-color: white !important;
    border-radius: 3px;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__submit {
    @include btn('100px');
  }

  &__cancel {
    @include cancel();
  }
}

/* Modal
  ============================================ */
	.modal {
		&__button {
			display: flex;
			align-items: center;
			padding: 0;
			background: transparent;
			border: none;
			border-radius: 0.25rem;
			font-weight: 600;
			cursor: pointer;

			@media (min-width: 1280px) {
				justify-content: flex-start;
			}

			&Icon {
				width: 1.5rem;
				height: 1.5rem;
				color: #FFF;
				transition: all 0.1s linear;
			}
		}

		&__dialog {
			&Title {
				margin-top: 0;
				color: #111827;
				font-size: 1.125rem;
				font-weight: 600;
				line-height: 1.5rem;

				svg {
					position: relative;
					top: -0.125rem;
					margin-right: 0.25rem;
				}
			}

			&Icon {
				display: inline;
				position: relative;
				top: -0.15rem;
				width: 1.125rem;
				height: 1.125rem;
				vertical-align: middle;
				color: #db4c3f;
			}

			&Frame {
				margin-top: 1rem;
			}

			&Disclaimer, &Clarification {
				color: #6b7280;
				font-size: 0.875rem;
				line-height: 1.25rem;
			}

			&Clarification {
				margin-top: 1.25rem;
			}

			&Button {
				display: flex;
				justify-content: center;
				margin-bottom: 1rem;
				padding: 0.5rem 1rem;
				color: #fff;
				background: #db4c3f;
				border-width: 1px;
				border-color: transparent;
				border-radius: 0.375rem;
				font-size: 0.875rem;
				font-weight: 500;
				line-height: 1.25rem;
				cursor: pointer;
				transition: all 0.1s linear;

				&:hover {
					opacity: 0.75;
				}

				@media (min-width: 425px) {
					display: inline-flex;
					margin-bottom: 0;
				}
			}
		}

		&__toggle {
			&Frame {
				margin-left: 0;
				-webkit-user-select: none; /* Safari */
				user-select: none;

				.react-toggle,
				.react-toggle-track,
				.react-toggle.inactive:hover .react-toggle-track {
					background-color: #e5e7eb;
				}

				.react-toggle--focus .react-toggle-thumb,
				.react-toggle:active .react-toggle-thumb {
					box-shadow: none;
				}

				.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
					box-shadow: none;
				}

				.react-toggle.active:hover .react-toggle-track,
				.active .react-toggle-track {
					background-color: #db4c3f;
				}

				.inactive {
					background-color: #db4c3f;
				}

				.react-toggle {
					margin-right: 0.5rem;
				}

				.react-toggle:focus-within {
					outline: -webkit-focus-ring-color auto 2px;
					outline: 2px solid black;
				}

				.react-toggle-thumb {
					top: 4px;
					left: 5px;
					border: none;
					width: 16px;
					height: 16px;
				}

				.react-toggle--checked .react-toggle-track {
					background-color: #db4c3f;
				}

				.react-toggle--checked .react-toggle-thumb {
					top: 4px;
					left: 24px;
				}

				.active,
				.inactive {
					display: inline-flex;
					align-items: center;
					position: relative;
					vertical-align: middle;
					width: 2.75rem;
					height: 1.5rem;
					border-radius: 9999px;
				}

				span {
					color: #6b7280;
					font-size: 0.875rem;
					font-weight: 600;
					line-height: 1.25rem;
				}

				@media (min-width: 425px) {
					margin-left: 1rem;
				}
			}

			&Message {
				-webkit-user-select: none; /* Safari */
				user-select: none;
			}
		}
	}

	.ReactModal__Overlay {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		inset: 0px;
		padding: 1rem;
		min-height: 100%;
		background-color: rgba(0, 0, 0, 0.75) !important;
	}

	.ReactModal__Content {
		width: 100%; 
		max-width: 28rem; 
		padding: 1.5rem; 
		vertical-align: middle; 
		text-align: left; 
		background-color: #ffffff; 
		opacity: 1;
		overflow: hidden; 
		border-radius: 1rem; 
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04); 
		transition-property: all; 
		--transform-scale-x: 1;
		--transform-scale-y: 1;

		&:focus-visible {
			outline: none;
		}
	}
